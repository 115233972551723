export const navLinksdata = [
  {
    _id: 1001,
    title: "الرئيسية",
    link: "home",
  },

  {
    _id: 1003,
    title: "المنشورات",
    link: "projects",
  },
  {
    _id: 1003,
    title: "معرض الاعمال",
    link: "Portfolio",
  },
  {
    _id: 1004,
    title: "حول",
    link: "resume",
  },
  {
    _id: 1006,
    title: "تواصل معي",
    link: "contact",
  },
];